<style scoped></style>

<template>
  <fm-poper-select @change="onChange" :value="value" :confirm="confirm" :options="options" :multiple="multiple" :clearable="clearable">
    <slot></slot>
  </fm-poper-select>
</template>

<script>
import { taskStatusList } from '../lib'
export default {
  name: 'task-status-select',
  props: {
    confirm: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    value: {
      type: [Array, String], default () {
        return null
      }
    }
  },
  computed: {
    options () {
      return taskStatusList.map(status => {
        return Object.assign({
          render (status) {
            return '<div class="' + status.cls + '">' + status.label + '</div>'
          }
        }, status)
      })
    }
  },
  methods: {
    onChange (data) {
      this.$emit('change', data)
    }
  }
}
</script>
