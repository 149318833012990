<style scoped></style>

<template>
  <status-select @change="onChange" :value="data" :confirm="confirm" :multiple="multiple" :clearable="clearable">
    <div :class="cls" style="cursor: pointer;">{{label}}</div>
  </status-select>
</template>

<script>
import StatusSelect from '../select/status'
import { taskStatusList } from '../lib'

export default {
  components: { StatusSelect },
  data () {
    return {
      data: this.status
    }
  },
  props: {
    confirm: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    status: {
      type: [Array, String], default () {
        return null
      }
    }
  },
  watch: {
    status () {
      this.data = this.status
    }
  },
  computed: {
    match () {
      return taskStatusList.find(v => v.value === this.data)
    },
    label () {
      if (this.multiple) {
        return this.data && this.data.length ? taskStatusList.filter(v => this.data.includes(v.value)).map(v => v.label).join(',') : '无'
      } else {
        return this.match ? this.match.label : '无'
      }
    },
    cls () {
      if (this.multiple) {
        return ''
      } else {
        return this.match ? this.match.cls : 'g-label-badge-default'
      }
    }
  },
  methods: {
    onChange (data) {
      this.data = data
      this.$emit('change', data)
    }
  },
}
</script>
